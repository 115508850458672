var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "p-2" }, [
            _vm.isAllowedToImportData
              ? _c("div", [
                  _c("div", { staticClass: "row mt-0 text-left" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h5", { staticClass: "mb-4" }, [
                        _c("i", { staticClass: "bx bx-reset mr-2" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "datafileResultGeneral.updateDatafileModal.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "mt-3 mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "datafileResultGeneral.updateDatafileModal.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.isUpdateSuccessfully,
                              dismissible: "",
                              variant: "success",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(
                                    "datafileResultGeneral.updateDatafileModal.alerts.success"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.isUpdateFailed,
                              dismissible: "",
                              variant: "danger",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(
                                    "datafileResultGeneral.updateDatafileModal.alerts.failed"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.isErrorInStructure,
                              dismissible: "",
                              variant: "warning",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(
                                    "datafileResultGeneral.updateDatafileModal.alerts.notMatch"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.otherError != null,
                              dismissible: "",
                              variant: "danger",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.otherError) + " ")]
                        ),
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.isNotEnoughCredits,
                              dismissible: "",
                              variant: "danger",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(
                                    "datafileResultGeneral.updateDatafileModal.alerts.notEnoughCredits"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mb-5" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep1.form.fileSelector.label"
                              )
                            ) +
                            " "
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                        _vm.isDatafileuploadProcessing && !_vm.fileReadError
                          ? _c("span", [
                              _c("i", {
                                staticClass: "bx bx-loader-circle bx-spin ml-2",
                              }),
                            ])
                          : _vm._e(),
                        _vm.hasDatafileSelected
                          ? _c(
                              "span",
                              {
                                staticClass: "ml-3 text-info pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.resetDatafile()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "mdi mdi-close-circle-outline mr-1",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "uploadProcessStep1.form.fileSelector.removeLabel"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      !_vm.hasDatafileSelected
                        ? _c(
                            "div",
                            { staticClass: "custom-file" },
                            [
                              _c("b-form-file", {
                                ref: "originalDatafile",
                                attrs: {
                                  id: "originalDatafile",
                                  placeholder: _vm.$t(
                                    "uploadProcessStep1.form.fileSelector.placeholder"
                                  ),
                                  "drop-placeholder": "Drop file here...",
                                  accept: ".xlsx",
                                },
                                on: { change: _vm.selectFile },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasDatafileSelected
                        ? _c("div", [
                            _vm._v(" " + _vm._s(this.newDatafileName) + " "),
                          ])
                        : _vm._e(),
                      _vm.fileReadError
                        ? _c("div", { staticClass: "text-danger mt-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep1.form.fileSelector.uploadErrorMsg"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "datafileResultGeneral.updateDatafileModal.newDataRecordsLabel"
                              )
                            ) +
                            ": "
                        ),
                      ]),
                      _c("span", { staticClass: "float-right" }, [
                        _vm._v(" " + _vm._s(this.newDatarecords)),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "datafileResultGeneral.updateDatafileModal.totalDataRecordsLabel"
                              )
                            ) +
                            ": "
                        ),
                      ]),
                      _c("span", { staticClass: "float-right" }, [
                        _vm._v(" " + _vm._s(this.totalDatarecords)),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "datafileResultGeneral.updateDatafileModal.newCreditsLabel"
                              )
                            ) +
                            ": "
                        ),
                      ]),
                      _c("span", { staticClass: "float-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.newCredits.toLocaleString(_vm.localeFormat, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.cleanDatafileCheckbox,
                                expression: "cleanDatafileCheckbox",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "CleanDatafileCheckbox",
                              disabled: !_vm.isCleaned,
                            },
                            domProps: {
                              checked: Array.isArray(_vm.cleanDatafileCheckbox)
                                ? _vm._i(_vm.cleanDatafileCheckbox, null) > -1
                                : _vm.cleanDatafileCheckbox,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.cleanDatafileCheckbox,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.cleanDatafileCheckbox = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.cleanDatafileCheckbox = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.cleanDatafileCheckbox = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover",
                                  value: _vm.cleaningCheckboxPopoverContent,
                                  expression: "cleaningCheckboxPopoverContent",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "custom-control-label",
                              attrs: { for: "CleanDatafileCheckbox" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-normal" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultGeneral.updateDatafileModal.cleanCheckBoxLabel"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _vm.hasDatafileSelected && _vm.isDatafileMatched
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { variant: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateDatafile()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "bx bx-reset" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$t(
                                        "datafileResultGeneral.updateDatafileModal.updateButton"
                                      )
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "light" },
                            on: {
                              click: function ($event) {
                                return _vm.clickCancel()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-x" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(
                                    "datafileResultGeneral.updateDatafileModal.cancelButton"
                                  )
                                )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row mt-0" }, [
                    _c("div", { staticClass: "col-11" }, [
                      _c("h5", { staticClass: "mb-4" }, [
                        _c("i", { staticClass: "bx bx-reset mr-2" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "datafileResultGeneral.updateDatafileModal.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-1 text-right" }, [
                      _c("i", {
                        staticClass: "bx bx-x",
                        staticStyle: {
                          "font-size": "x-large",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickCancel()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-12 text-center p-5" },
                    [
                      _c("img", {
                        staticStyle: { height: "60px" },
                        attrs: {
                          src: require("@/assets/images/information.png"),
                        },
                      }),
                      _c("h5", { staticClass: "mb-3 mt-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("liveImportStep1.contractPause.text1"))
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("liveImportStep1.contractPause.text2")
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.clickOnContact()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bx bx-envelope font-size-18 mr-2",
                          }),
                          _vm._v(
                            _vm._s(
                              _vm.$t("liveImportStep1.contractPause.button")
                            ) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
          ])
        : _c("div", [
            _c("div", { staticClass: "d-flex justify-content-center" }, [
              _c("h5", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileResultGeneral.updateDatafileModal.calculating"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "m-4" },
              [
                _c(
                  "b-progress",
                  {
                    staticStyle: { "font-size": "15px" },
                    attrs: {
                      max: 100,
                      "show-progress": "",
                      animated: "",
                      height: "30px",
                    },
                  },
                  [
                    _c("b-progress-bar", {
                      attrs: { value: _vm.uploadProgress, "show-value": "" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex" }, [
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileResultGeneral.updateDatafileModal.bePatient.title"
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileResultGeneral.updateDatafileModal.bePatient.text1"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "p-4 col-md-12 text-center" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.clickMakeUpdateBackground()
                      },
                    },
                  },
                  [_vm._v("Back to Datafile ")]
                ),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }