import { render, staticRenderFns } from "./CS-tab.vue?vue&type=template&id=555e4e60"
import script from "./CS-tab.vue?vue&type=script&lang=js"
export * from "./CS-tab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('555e4e60')) {
      api.createRecord('555e4e60', component.options)
    } else {
      api.reload('555e4e60', component.options)
    }
    module.hot.accept("./CS-tab.vue?vue&type=template&id=555e4e60", function () {
      api.rerender('555e4e60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/datafiles/components/modals/respondent-modal/CS-tab.vue"
export default component.exports